import {
  IconCheck,
  IconMessage,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import {
  MouseEventHandler,
  useContext,
  useState,
} from 'react';
import { ChatFolderDetail } from '../../interfaces/chat.interface';
import { ChatContext } from '../../store/chat.store';
import { v4 as uuidv4 } from 'uuid';
import useChatLocalService from '../../services/chat-local.service';
import { ConversationType } from '../../constants/chat.constant';
import useChatApiService from '../../services/chat.service';
import toast from 'react-hot-toast';

interface Props {
  conversationType: ConversationType;
  conversation: ChatFolderDetail;
}

export const ConversationComponent = ({ conversation, conversationType }: Props) => {
  const {
    state: { selectedConversation, conversations, conversationStatusMap },
    dispatch
  } = useContext(ChatContext);
  const [isDeleting, setIsDeleting] = useState(false);
  const { saveSelectedConversation } = useChatLocalService();
  const { deleteChatConversation } = useChatApiService();

  const updateConversationsAfterDeletion = (conversation: ChatFolderDetail) => {
    // Remove current selected conversation
    const updatedConversations = conversations.filter(
      (c) => c.conversation_id !== conversation.conversation_id,
    );
    dispatch({ field: 'conversations', value: updatedConversations });

    let selectedConversation: any = {
      conversation_id: uuidv4(),
      title: 'New Conversation',
      conversation_type: conversationType
    };

    if (updatedConversations.length > 0) {
      selectedConversation = updatedConversations[updatedConversations.length - 1];
    } else {
      dispatch({ field: 'conversationStatusMap', value: conversationStatusMap.set(selectedConversation.conversation_id, false) })
    }

    dispatch({
      field: 'selectedConversation',
      value: selectedConversation
    });
    saveSelectedConversation(selectedConversation.conversation_id, selectedConversation.conversation_type);
  }

  const handleDeleteConversation = (conversation: ChatFolderDetail) => {
    const isExistingConversation = conversationStatusMap.has(conversation.conversation_id) ? conversationStatusMap.get(conversation.conversation_id) : true;

    if (!isExistingConversation) {
      updateConversationsAfterDeletion(conversation);
    } else {
      dispatch({ field: 'isLoading', value: true });

      deleteChatConversation(conversation.conversation_id, conversation.conversation_type).then((response) => {
        updateConversationsAfterDeletion(conversation);
        dispatch({ field: 'isLoading', value: false });
        toast.success(response.message);
      }).catch((err) => {
        console.error('Error occurred while deleting user conversation', err);

        dispatch({ field: 'isLoading', value: false });
        toast.error((err && err.error) ? err.error : 'Something went wrong.');
      });
    }
  };

  const handleSelectConversation = (conversation: ChatFolderDetail) => {
    dispatch({
      field: 'selectedConversation',
      value: conversation
    });

    saveSelectedConversation(conversation.conversation_id, conversation.conversation_type);
  };

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (isDeleting) {
      handleDeleteConversation(conversation)
    }
    setIsDeleting(false);
  };

  const handleCancel: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsDeleting(false);
  };

  const handleOpenDeleteModal: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsDeleting(true);
  };

  return (
    <div className="relative flex items-center">
      <button
        className={`flex w-full cursor-pointer items-center gap-3 p-3 text-sm transition-colors duration-200 dark:hover:bg-[#343541]/90 
          ${selectedConversation?.conversation_id === conversation.conversation_id
            ? 'bg-[#e4e0dc] text-[#665546] dark:text-white dark:bg-[#343541]/90'
            : 'text-[#665546] dark:text-white'
          }`}
        draggable="true"
        onClick={() => handleSelectConversation(conversation)}
      >
        <IconMessage size={18} />
        <div
          className={`relative max-h-5 flex-1 overflow-hidden text-ellipsis whitespace-nowrap break-all text-left text-[12.5px] leading-3 ${selectedConversation?.conversation_id === conversation.conversation_id ? 'pr-12' : 'pr-1'
            }`}
        >
          {conversation.title}
        </div>
      </button>

      {(isDeleting) &&
        selectedConversation?.conversation_id === conversation.conversation_id && (
          <div className="absolute right-1 z-10 flex text-gray-300">
            <button
              className="min-w-[20px] p-1 text-neutral-400 hover:text-neutral-100"
              onClick={handleConfirm}
            >
              <IconCheck size={18} />
            </button>
            <button
              className="min-w-[20px] p-1 text-neutral-400 hover:text-neutral-100"
              onClick={handleCancel}
            >
              <IconX size={18} />
            </button>
          </div>
        )}

      {selectedConversation?.conversation_id === conversation.conversation_id &&
        !isDeleting && (
          <div className="absolute right-1 z-10 flex text-[#665546] dark:text-gray-300">
            <button
              className="min-w-[20px] p-1 text-neutral-400 hover:text-neutral-100"
              onClick={handleOpenDeleteModal}
            >
              <IconTrash size={18} />
            </button>
          </div>
        )}
    </div>
  );
};
