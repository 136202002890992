import { IconBoxMultiple1, IconBoxMultiple2, IconBoxMultiple3, IconBoxMultiple4 } from "@tabler/icons-react";
import { useEffect, KeyboardEvent, useState, useCallback, useContext, useRef } from "react";
import { ChatMessage } from "../../components/chat/chat-message";
import { ChatLoader } from "../../components/chat/chat-loader";
import { ChatInitialState, Message } from "../../interfaces/chat.interface";
import { useCreateReducer } from "../../hooks/useCreateReducer";
import { CHAT_INITIAL_STATE, ChatContext } from "../../store/chat.store";
import placeholderChatImg from '../../assets/not-found-1.svg';
import logoLight from '../../assets/chop-logo-white.svg';
import logoDark from '../../assets/chop-logo-black.svg';
import { ThemeContext } from "../../store/theme.store";
import ChatSideBar from "../../components/chat/chat-sidebar";
import ChatInput from "../../components/chat/chat-input";
import useChatLocalService from "../../services/chat-local.service";
import useChatApiService from "../../services/chat.service";
import { ConversationType } from "../../constants/chat.constant";
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from "react-query";
import FullScreenLoader from "../../components/loader/fullscreen-loader";
import toast from "react-hot-toast";

export default function PolicyGptChat() {
    const defaultConversationType = ConversationType.DOCUMENT_GPT;
    const suggestions = [
        {
            prompt: 'What are the standards for the insertion of a Central Venous Catheter (CVC)?',
            icon: IconBoxMultiple1
        },
        {
            prompt: 'What are the types of PPL provided by CHOP?',
            icon: IconBoxMultiple2
        },
        {
            prompt: 'What are the precautions for measles?',
            icon: IconBoxMultiple3
        },
        {
            prompt: 'What are the steps for administering intramuscular injections',
            icon: IconBoxMultiple4
        }
    ];

    const { getSelectedConversation, saveSelectedConversation } = useChatLocalService();
    const { getAllChatFolders, getChatConversation, getPolicyGptResponse } = useChatApiService();
    const messagesEndRef = useRef<null | HTMLDivElement>(null)
    // const chatDivRef = useRef(null);

    const contextValue = useCreateReducer<ChatInitialState>({
        initialState: CHAT_INITIAL_STATE
    });
    const { state: { isDarkTheme } } = useContext(ThemeContext);
    const { data: chatFolderDetails, ...chatFolderState } = useQuery(
        ['GetPolicyChatFolderDetails'],
        () => getAllChatFolders(defaultConversationType)
    );

    const [content, setContent] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentMessage, setCurrentMessage] = useState<Message>();
    const [isResponseGenerating, setIsResponseGenerating] = useState<boolean>(false);
    const [activeConversation, setActiveConversation] = useState<Array<Message> | null>([]);
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const { state, dispatch } = contextValue;


    // Event listeners
    const isMobile = () => {
        const userAgent =
            typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
        const mobileRegex =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
        return mobileRegex.test(userAgent);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setContent(value);
    };

    const handleSend = async (message: Message, regenerate: boolean = false) => {
        message.content = (message.content ? message.content : '').trim();
        setIsResponseGenerating(true)

        if (state.selectedConversation && message.content) {
            setCurrentMessage(message);
            setActiveConversation((prevState) => {
                if (!prevState) { prevState = []; }

                if (regenerate) {
                    prevState.pop();
                } else {
                    prevState = [...prevState, message];
                }

                return prevState;
            });

            setIsLoading(true);
            if (isResponseGenerating)
                return
            // TODO: API call to get the chat response
            getPolicyGptResponse({
                conversationId: state.selectedConversation.conversation_id,
                conversationTitle: state.selectedConversation.title,
                message: message.content
            }, regenerate).then((response) => {
                if (response && response.data) {
                    setActiveConversation((prevState) => [...(prevState ? prevState : []), { role: 'assistant', content: response.data.ai_response, sources: JSON.stringify(response.data.sources) }]);
                    if (state.conversationStatusMap.has(state.selectedConversation.conversation_id)) {
                        const updatedConversations = [];
                        for (const item of state.conversations) {
                            if (item && item.conversation_id === state.selectedConversation.conversation_id) {
                                item.title = response.data.conversation_title;
                            }

                            updatedConversations.push(item);
                        }

                        dispatch({ field: 'conversations', value: updatedConversations });
                        dispatch({ field: 'conversationStatusMap', value: state.conversationStatusMap.set(state.selectedConversation.conversation_id, true) });
                    }
                }
                setIsResponseGenerating(false)
                setIsLoading(false);
            }).catch((err) => {
                console.error('ERROR: Error occurred while getting chat gpt response', err);
                setIsResponseGenerating(false)

                setIsLoading(false);
            });
        }

        // Clear the user input
        setContent('');
    };

    useEffect(() => {
        if (messagesEndRef) {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    }, [activeConversation]);

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !isResponseGenerating && !isTyping && !isMobile() && !e.shiftKey) {
            e.preventDefault();
            setIsResponseGenerating(true)
            handleSend({
                role: 'user',
                content: content as string
            });
        }
    };

    const handleRegenerateResponse = () => {
        let message = currentMessage;
        if (!message && activeConversation && activeConversation.length) {
            for (let i = activeConversation.length - 1; i >= 0; i--) {
                if (activeConversation[i] && activeConversation[i].role === 'user') {
                    message = activeConversation[i];
                    break;
                }
            }
        }

        if (message) { handleSend(message, true); }
    };

    // useEffects
    useEffect(() => {
        if (chatFolderDetails && chatFolderDetails.data) {
            // Check whether the user has any selected conversation
            const selectedConversationId = getSelectedConversation(defaultConversationType);
            let conversation = {
                conversation_id: uuidv4(),
                title: 'New Conversation',
                conversation_type: defaultConversationType
            }
            let parsedConversations = chatFolderDetails.data;
            if (!parsedConversations.length) {
                parsedConversations.push(conversation);
                dispatch({ field: 'conversationStatusMap', value: state.conversationStatusMap.set(conversation.conversation_id, false) });
            } else {
                const selectedConversation = parsedConversations.filter((value) => value.conversation_id === selectedConversationId);
                conversation = (selectedConversation.length ? selectedConversation[0] : parsedConversations[0]);
            }

            dispatch({
                field: 'selectedConversation',
                value: conversation
            });
            saveSelectedConversation(conversation.conversation_id, defaultConversationType);

            dispatch({ field: 'conversations', value: parsedConversations });
        }

        if (chatFolderState.isError && !chatFolderState.isLoading) {
            toast.error(chatFolderState.error ? (chatFolderState.error as string) : 'Something went wrong.');
        }
    }, [chatFolderDetails]);

    useEffect(() => {
        const selectedConversationId = state.selectedConversation.conversation_id.trim();
        const isExisting = (state.conversationStatusMap.has(selectedConversationId)) ? state.conversationStatusMap.get(selectedConversationId) : true;

        if (selectedConversationId && isExisting) {
            // Get user conversation details
            dispatch({ field: 'isLoading', value: true });
            getChatConversation(selectedConversationId, defaultConversationType).then((response) => {
                const parsedConversation: Array<Message> = [];

                if (response && response.data) {
                    for (const item of response.data) {
                        parsedConversation.push({ role: 'user', content: item.human });
                        parsedConversation.push({ role: 'assistant', content: item.ai, sources: item.sources });
                    }
                }
                setActiveConversation(parsedConversation);

                dispatch({ field: 'isLoading', value: false });
                toast.success(response.message);
            }).catch((err) => {
                console.error('ERROR: Error occurred while getting chat conversation details', err);
                setActiveConversation(null);

                dispatch({ field: 'isLoading', value: false });
                toast.error((err && err.error) ? err.error : 'Something went wrong.');
            });
        } else {
            setActiveConversation([]);
        }
    }, [state.selectedConversation]);

    return (
        <div className="chat-wrapper h-full">
            <ChatContext.Provider value={{
                ...contextValue
            }}>
                <div className="flex flex-wrap h-full">
                    <div className="hidden md:flex flex-col justify-between col-4 h-full bg-[#fefefe] dark:bg-[#181818]">
                        <ChatSideBar onNewChat={() => setContent('')} conversationType={defaultConversationType} />
                    </div>
                    <div className={`flex-1 relative h-full ${(isDarkTheme) ? "dark" : "bg-[#f1f1f1]"}`}>

                        {(state.selectedConversation && state.selectedConversation.conversation_id) && activeConversation ?
                            <div
                                className="h-[90%] overflow-x-hidden bg-[#f1f1f1] dark:bg-[#212121]"
                            >
                                {(activeConversation && activeConversation.length) > 0 ? (
                                    <>
                                        {/* <div className="flex justify-center border border-b-neutral-300 bg-neutral-100 py-2 text-sm text-neutral-500 dark:border-none dark:bg-[#2e2e2e] dark:text-neutral-200">
                                            CHOP AI

                                            <button
                                                className="ml-2 cursor-pointer hover:opacity-50"
                                            >
                                                <IconClearAll size={18} />
                                            </button>
                                        </div> */}

                                        {activeConversation.map((message, index) => (
                                            <ChatMessage
                                                key={index}
                                                message={message}
                                                messageIndex={index}
                                            />
                                        ))}
                                        <div ref={messagesEndRef} className="h-14 w-full" />

                                        {isLoading && <ChatLoader />}

                                    </>
                                ) : (
                                    <>
                                        <div className="chat-intro-placeholder h-full relative flex justify-center">
                                            <div className="absolute top-4 md:top-1/4 w-full flex flex-col items-center justify-center">
                                                <div className="brand-logo h-11 hidden md:block">
                                                    <img src={(isDarkTheme) ? logoLight : logoDark} alt="CHOP Logo" className="h-full" />
                                                </div>
                                                <div className="suggestions p-5 flex flex-wrap justify-center flex-col items-center md:flex-row">
                                                    {suggestions && suggestions.map((value, index) => (
                                                        <button key={index}
                                                            className="text-sidebar text-sm ml-3 mt-3 flex h-[100px] w-[160px] flex-shrink-0 flex-col cursor-pointer select-none items-start justify-center gap-3 border border-gray-200 dark:border-white/10 p-3 text-white transition-colors duration-200 hover:bg-gray-500/10"
                                                            onClick={() => handleSend({ role: 'user', content: value.prompt })}
                                                        >
                                                            <value.icon size={20} color="#34a0bb" />
                                                            <div
                                                                className="line-clamp-2 text-balance text-gray-600 dark:text-gray-400 break-word text-left w-full overflow-ellipsis"
                                                            >
                                                                {value.prompt}
                                                            </div>

                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className="chat-input-wrapper">
                                    <ChatInput
                                        activeConversation={activeConversation}
                                        content={content}
                                        onStartTyping={() => setIsTyping(true)}
                                        onStopTyping={() => setIsTyping(false)}
                                        onInputChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        onRegenerateResponse={handleRegenerateResponse}
                                        onSend={handleSend}
                                        isResponseGenerating={isResponseGenerating}
                                    />
                                </div>
                            </div> :

                            <div className="h-full overflow-x-hidden bg-[#f1f1f1] dark:bg-[#212121] flex items-center justify-center">
                                <div className="placeholder-chat-img flex flex-col items-center justify-center">
                                    <img src={placeholderChatImg} alt="No conversation" className="w-[300px]" />
                                    <div className="mt-5 text-gray-400">No conversation selected.</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </ChatContext.Provider>

            {state.isLoading && <FullScreenLoader />}
        </div>
    )
};