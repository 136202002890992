import { useContext, useState } from 'react';
import chopIcon from '../../assets/chop-icon.svg';
import { ThemeContext } from '../../store/theme.store';
import { Link, useLocation } from 'react-router-dom';
import { IconLogout2 } from '@tabler/icons-react';
import useAuthService from '../../services/auth.service';
import useThemeLocalService from '../../services/theme-local.service';
import { THEME } from '../../constants/app.constant';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

interface Props {
    userDetail: {
        name?: string;
        email: string;
    }
}

interface NavMenu {
    href: string
    name: string,
}

const enum NAV_MENU {
    GPT_CHAT = '/gpt',
    POLICY_CHAT = '/policy'
};

export default function Header({ userDetail }: Props) {
    const { state, dispatch } = useContext(ThemeContext);
    const { setTheme } = useThemeLocalService();

    const NAV_MENU_MAP: { [key: string]: NavMenu } = {
        [NAV_MENU.GPT_CHAT]: { name: 'CHOP GPT', href: NAV_MENU.GPT_CHAT },
        [NAV_MENU.POLICY_CHAT]: { name: 'Policy GPT', href: NAV_MENU.POLICY_CHAT }
    };
    // Commented-out for #4
    /*const [navigation, setNavigation] = useState<Array<NavMenu>>([NAV_MENU_MAP[NAV_MENU.GPT_CHAT], NAV_MENU_MAP[NAV_MENU.POLICY_CHAT]]);*/
    const [navigation, setNavigation] = useState<Array<NavMenu>>([NAV_MENU_MAP[NAV_MENU.GPT_CHAT]]);
    const location = useLocation();
    const { logout } = useAuthService();

    const onLogout = () => {
        logout();
    };

    const onThemeChange = () => {
        const themeValue = !state.isDarkTheme;

        setTheme(themeValue ? THEME.DARK : THEME.LIGHT);
        dispatch({ field: 'isDarkTheme', value: themeValue });
    };

    return (
        <div className="header-wrapper py-4 px-5 md:px-5 flex justify-between bg-[#fefefe] dark:bg-[#181818]">
            <div className="left-section flex items-center justify-center">
                <div className="brand-logo h-[35px] md:h-9">
                    <img src={chopIcon} alt="CHOP Logo" className="h-full" />
                </div>
                <div className="flex space-x-4 ml-5">
                    {navigation.map((item, index) => (
                        <Link to={item.href} key={index}>
                            <button
                                id="theme-toggle"
                                type="button"
                                className={classNames(
                                    ((item.href === location.pathname) ? 'active' : ''),
                                    'text-[#665546] dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 font-medium focus:outline-none text-sm p-2.5'
                                )}
                            >
                                {item.name}
                            </button>
                        </Link>
                    ))}
                </div>
            </div>

            <div className="header-right-section flex">
                <button
                    id="theme-toggle"
                    type="button"
                    className="text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg focus:outline-none focus:ring-gray-700 text-sm p-2.5"
                    onClick={onThemeChange}
                >
                    <svg
                        id="theme-toggle-light-icon"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        {state.isDarkTheme ?
                            <path
                                d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
                            ></path> :
                            <path
                                d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                                fillRule="evenodd"
                                clipRule="evenodd"
                            ></path>
                        }

                    </svg>
                </button>

                {userDetail &&
                    <div className="user-profile flex items-center ml-5 md:ml-5 gap-3">
                        {userDetail.name &&
                            <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-chop-blue rounded-full">
                                <span className="font-medium">{userDetail.name.charAt(0)}</span>
                            </div>
                        }
                        <div className="hidden md:block font-medium text-black dark:text-white">
                            <div>{userDetail.name}</div>
                            <div className="text-sm text-gray-400">{userDetail.email}</div>
                        </div>
                    </div>
                }

                    <button
                        id="theme-toggle"
                        type="button"
                        className="text-gray-400 ml-3 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none focus:ring-gray-700 rounded-lg text-sm p-2.5"
                        onClick={onLogout}
                    >

                            <IconLogout2 />


                </button>
                </div>

        </div>
    )
};