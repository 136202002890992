import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { LOGIN_SCOPES } from "../../constants/auth.constant";

export const Login = () => {
    const { instance } = useMsal();

    const login = () => {
        instance.loginRedirect({
            scopes: LOGIN_SCOPES,
            redirectStartPage: '/'
        }).then((res: any) => {
            console.log('LOG: User logged in');
        }).catch((e: any) => {
            console.error('ERROR: Error occurred during user login', e);
        });
    }

    useEffect(() => {
        login()
    }, []);

    return (<></>)
}
