import { ConversationType } from '../constants/chat.constant';

const useChatLocalService = () => {
  const getSelectedConversation = (conversationType: ConversationType) => {
    return localStorage.getItem(conversationType);
  };

  const saveSelectedConversation = (conversationId: string, conversationType: ConversationType) => {
    localStorage.setItem(conversationType, conversationId);
  };

  const clearAll = () => {
    localStorage.removeItem(ConversationType.CHAT_GPT);
    localStorage.removeItem(ConversationType.DOCUMENT_GPT);
  }

  return {
    getSelectedConversation,
    saveSelectedConversation,
    clearAll
  };
};

export default useChatLocalService;