import {useEffect} from "react";
import 'ldrs/waveform'
import { waveform } from 'ldrs'

waveform.register()


export const Skeleton = () => {

    return (
        <>
            <l-waveform
                size="25"
                stroke="3.5"
                speed="1"
                color="#33a0bb"
            ></l-waveform>
        </>
    )
}