import { IconPlayerStop, IconRepeat, IconSend } from "@tabler/icons-react";
import { KeyboardEvent, useContext, useRef } from "react";
import { ChatContext } from "../../store/chat.store";
import { Message } from "../../interfaces/chat.interface";

interface Props {
    content: string | undefined;
    activeConversation: Array<Message> | null;
    onSend: (message: Message) => void;
    onInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    onStartTyping: () => void;
    onStopTyping: () => void;
    onRegenerateResponse: () => void;
    isResponseGenerating : boolean;
}

export default function ChatInput({
    content,
    activeConversation,
    onSend,
    onInputChange,
    onKeyDown,
    onStartTyping,
    onStopTyping,
    onRegenerateResponse,
    isResponseGenerating
}: Props) {
    const messageIsStreaming = false;

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { state } = useContext(ChatContext);

    return (
        <div className="chat-input absolute bottom-5 left-0 w-full border-transparent pt-6 dark:border-white/20 md:pt-2 dark:bg-transparent">
            <div className="stretch mx-2 mt-4 flex flex-row gap-3 last:mb-2 md:mx-4 md:mt-[52px] md:last:mb-6 lg:mx-auto lg:max-w-3xl">
                <div className="relative bottom-5 mx-2 flex w-full flex-grow flex-col border border-black/10 bg-white shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:border-gray-900/50 dark:bg-[#2e2e2e] dark:text-white dark:shadow-[0_0_15px_rgba(0,0,0,0.10)] sm:mx-4">
                    <textarea
                        ref={textareaRef}
                        className="m-0 w-full resize-none border-0 bg-transparent p-0 py-2 pr-8 pl-7 text-black dark:bg-transparent dark:text-white md:py-3 md:pl-7 outline-none"
                        style={{
                            resize: 'none',
                            bottom: `${textareaRef?.current?.scrollHeight}px`,
                            maxHeight: '400px',
                            overflow: `${textareaRef.current && textareaRef.current.scrollHeight > 400
                                ? 'auto'
                                : 'hidden'
                                }`,
                        }}
                        placeholder='How can I assist you today?'
                        value={content}
                        rows={3}
                        onCompositionStart={onStartTyping}
                        onCompositionEnd={onStopTyping}
                        onChange={onInputChange}
                        onKeyDown={onKeyDown}
                    />

                    <button
                        className="absolute right-2 bottom-2 p-1 text-neutral-800 opacity-60 hover:text-neutral-900 dark:bg-opacity-50 dark:text-neutral-100 dark:hover:text-neutral-200"
                        onClick={() => onSend({ role: 'user', content: content as string })}
                        disabled={isResponseGenerating}
                    >
                        {(messageIsStreaming || isResponseGenerating) ? (
                            <div className="h-4 w-4 animate-spin border-t-2 border-neutral-800 opacity-60 dark:border-neutral-100"></div>
                        ) : (
                            <IconSend size={18} />
                        )}
                    </button>
                </div>
                <div>
                    {messageIsStreaming && (
                        <button
                            className="absolute bottom-10 left-0 right-0 mx-auto mb-3 flex w-fit items-center gap-3 border border-neutral-200 bg-white py-2 px-4 text-black hover:opacity-50 dark:border-neutral-600 dark:bg-[#212121] dark:text-white md:mb-0 md:mt-2"
                        >
                            <IconPlayerStop size={16} /> Stop Generating
                        </button>
                    )}

                    {!isResponseGenerating && !messageIsStreaming && activeConversation && activeConversation.length > 0 && (
                        <button
                            className="absolute bottom-0 left-0 right-0 mx-auto mb-3 flex w-fit items-center gap-3 border border-neutral-200 bg-white py-2 px-4 text-black hover:opacity-50 dark:border-neutral-600 dark:bg-[#212121] dark:text-white md:mb-0 md:mt-2"
                            onClick={onRegenerateResponse}
                        >
                            <IconRepeat size={16} /> Regenerate response
                        </button>
                    )}
                </div>


            </div>

        </div>
    )
}
