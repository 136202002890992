import { IconPlus } from "@tabler/icons-react";
import { useContext } from "react";
import { ChatContext } from "../../store/chat.store";
import { Conversations } from "./conversations";
import { v4 as uuidv4 } from 'uuid';
import useChatLocalService from "../../services/chat-local.service";
import { ConversationType } from "../../constants/chat.constant";

interface Props {
    conversationType: ConversationType;
    onNewChat: () => void;
}

export default function ChatHistory({
    conversationType,
    onNewChat
}: Props) {
    const { state, dispatch } = useContext(ChatContext);
    const { saveSelectedConversation } = useChatLocalService();

    // Event listeners
    const handleNewChat = () => {
        const newConversation = {
            conversation_id: uuidv4(),
            title: 'New Conversation',
            conversation_type: conversationType
        };

        const updatedConversations = [...state.conversations, newConversation];

        dispatch({ field: 'conversationStatusMap', value: state.conversationStatusMap.set(newConversation.conversation_id, false) });
        dispatch({ field: 'selectedConversation', value: newConversation});
        dispatch({ field: 'conversations', value: updatedConversations });

        saveSelectedConversation(newConversation.conversation_id, conversationType);

        // Output event to parent
        onNewChat();
    }

    return (
        <div className="chat-history-wrapper h-full p-4">
            <div className="chat-history-controls h-full">
                <button
                    className="bg-[#958579] dark:bg-black text-sidebar text-sm flex w-[100%] flex-shrink-0 cursor-pointer select-none items-center gap-3 border border-white/20 p-2.5 text-white transition-colors duration-200 dark:hover:bg-gray-500/10"
                    onClick={handleNewChat}
                >
                    <IconPlus size={16} />
                    New chat
                </button>

                <div className="mt-5 conversations-section overflow-auto">
                    {state.conversations.length > 0 && <Conversations conversations={state.conversations} conversationType={conversationType} />}
                </div>
            </div>
        </div>
    )
}