import { useCallback } from "react";
import { API_BASE_URL } from "../constants/app.constant";
import { useFetch } from "../hooks/useFetch";
import { ConversationType } from "../constants/chat.constant";
import { APIResponse } from "../interfaces/common.interface";
import {
    ChatConversationDetail,
    ChatFolderDetail,
    ChatGptPayload,
    ChatGptResponse, PresignedUrl
} from "../interfaces/chat.interface";
import useAuthService from "./auth.service";


const useChatApiService = () => {
    const fetchService = useFetch();
    const { getUserAccessToken } = useAuthService();
    const CHAT_API_BASE_URL = API_BASE_URL + '/chat';
    const CHAT_HISTORY_API_BASE_URL = CHAT_API_BASE_URL + '/history';

    const getAllChatFolders = useCallback(async (conversationType: ConversationType | null = null, signal?: AbortSignal) => {
        // Get current user access token
        const token = await getUserAccessToken();
        if (!token) { throw new Error('Invalid token'); }

        let url = CHAT_HISTORY_API_BASE_URL;
        if (conversationType) { url = url + `?type=${conversationType}`; }

        return fetchService.get<APIResponse<Array<ChatFolderDetail>>>(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            signal
        })
    }, [fetchService]);

    const getChatConversation = useCallback(async (conversationId: string, conversationType: ConversationType, signal?: AbortSignal) => {
        // Get current user access token
        const token = await getUserAccessToken();
        if (!token) { throw new Error('Invalid token'); }

        return fetchService.get<APIResponse<Array<ChatConversationDetail>>>(`${CHAT_HISTORY_API_BASE_URL}?type=${conversationType}&id=${conversationId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            signal
        })
    }, [fetchService]);
    
    const deleteChatConversation = useCallback(async (conversationId: string, conversationType: ConversationType, signal?: AbortSignal) => {
        // Get current user access token
        const token = await getUserAccessToken();
        if (!token) { throw new Error('Invalid token'); }

        return fetchService.delete<APIResponse<string>>(`${CHAT_HISTORY_API_BASE_URL}?type=${conversationType}&id=${conversationId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            signal
        })
    }, [fetchService]);

    const deleteAllChatConversation = useCallback(async (conversationType: ConversationType, signal?: AbortSignal) => {
        // Get current user access token
        const token = await getUserAccessToken();
        if (!token) { throw new Error('Invalid token'); }

        return fetchService.delete<APIResponse<string>>(`${CHAT_HISTORY_API_BASE_URL}?type=${conversationType}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            signal
        })
    }, [fetchService]);

    const getChatGptResponse = useCallback(async (payload: ChatGptPayload, regenerate: boolean = false, signal?: AbortSignal) => {
        // Get current user access token
        const token = await getUserAccessToken();
        if (!token) { throw new Error('Invalid token'); }

        return fetchService.post<APIResponse<ChatGptResponse>>(`${CHAT_API_BASE_URL}?type=${ConversationType.CHAT_GPT}&regenerate=${regenerate}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: payload,
            signal
        })
    }, [fetchService]);

    const getPolicyGptResponse = useCallback(async (payload: ChatGptPayload, regenerate: boolean = false, signal?: AbortSignal) => {
        // Get current user access token
        const token = await getUserAccessToken();
        if (!token) { throw new Error('Invalid token'); }

        return fetchService.post<APIResponse<ChatGptResponse>>(`${CHAT_API_BASE_URL}?type=${ConversationType.DOCUMENT_GPT}&regenerate=${regenerate}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: payload,
            signal
        })
    }, [fetchService]);

    // Function to get pre-signed url

    const getPresignedUrl = useCallback(async (payload: PresignedUrl, regenerate: boolean = false, signal?: AbortSignal) => {
        // Get current user access token
        const token = await getUserAccessToken();
        if (!token) { throw new Error('Invalid token'); }

        return fetchService.post<PresignedUrl>(`${CHAT_API_BASE_URL}?type=${ConversationType.DOCUMENT_URL}&regenerate=${regenerate}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: payload,
            signal
        })
    }, [fetchService]);


    return {
        getAllChatFolders,
        getChatConversation,
        getChatGptResponse,
        getPolicyGptResponse,
        deleteChatConversation,
        deleteAllChatConversation,
        getPresignedUrl
    }
}

export default useChatApiService;