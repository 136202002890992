import { Outlet } from "react-router-dom";
import Header from "../../components/header/header";
import { useThemeDetector } from "../../hooks/useThemeDetector";
import { useCreateReducer } from "../../hooks/useCreateReducer";
import { ThemeInitialState } from "../../interfaces/app.interface";
import { ThemeContext } from "../../store/theme.store";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Login } from "../login/login";
import { Toaster } from "react-hot-toast";

const Layout = () => {
    const isDarkTheme = useThemeDetector();
    const contextValue = useCreateReducer<ThemeInitialState>({
        initialState: { isDarkTheme }
    });
    const { state } = contextValue;
    const { accounts } = useMsal();

    return (
        <>
            {accounts && accounts.length ?
                <AuthenticatedTemplate>
                    <ThemeContext.Provider value={contextValue} >
                        <div className={(state.isDarkTheme ? 'dark ' : '') + "layout-wrapper h-full text-white"}>
                            <Header userDetail={{ name: accounts[0].name, email: accounts[0].username }} />
                            <div className="page-wrapper bg-page">
                                <Outlet />
                            </div>
                        </div>
                    </ThemeContext.Provider>
                    <Toaster position="top-right" reverseOrder={false} />
                </AuthenticatedTemplate> :

                <UnauthenticatedTemplate>
                    <Login />
                </UnauthenticatedTemplate>
            }
        </>
    );
};

export default Layout;
