export default function FullScreenLoader() {
    return (
        <div className="loader-wrapper">
            <div className="loader">
                <svg className='circular' viewBox='25 25 50 50'>
                    <circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth='5' strokeMiterlimit='10' ></circle>
                </svg>
            </div>
        </div>
    )
};