import { PublicClientApplication } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const MSAL_DEAFULT_CONFIG = {
    auth: {
        clientId: "6deeaf3f-449a-4d16-ac59-d02bbc7e4386",
        authority: "https://login.microsoftonline.com/a6112416-07b0-41a5-9bb1-d146b575c975",
        redirectUri: import.meta.env.VITE_REDIRECT_URI,
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
    }
};

// Group allowed to access the app
export const ALLOWED_AD_GROUP = "CHOP AI Assistant Users";

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const LOGIN_SCOPES = ["User.Read"];

// Creating msal app globally
export const msalApp = new PublicClientApplication(MSAL_DEAFULT_CONFIG);