
import { createContext } from 'react';
import { ChatContextProps, ChatInitialState } from '../interfaces/chat.interface';
import { ConversationType } from '../constants/chat.constant';

export const CHAT_INITIAL_STATE: ChatInitialState = {
  conversations: [],
  conversationStatusMap: new Map(),
  selectedConversation: {
    conversation_id: '',
    title: '',
    conversation_type: ConversationType.CHAT_GPT
  },
  isLoading: false
};

export const ChatContext = createContext<ChatContextProps>(undefined!);
