import { THEME } from '../constants/app.constant';

const useThemeLocalService = () => {
  const THEME_KEY = 'theme';

  const getTheme = () => {
    return localStorage.getItem(THEME_KEY);
  };

  const setTheme = (theme: THEME) => {
    localStorage.setItem(THEME_KEY, theme);
  };

  return {
    getTheme,
    setTheme
  };
};

export default useThemeLocalService;