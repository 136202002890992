import React from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import Layout from "./pages/layout/layout";
import GptChat from "./pages/gpt-chat/chat";
import { QueryClient, QueryClientProvider } from "react-query";
import PolicyGptChat from "./pages/policy-gpt/policy-chat";
import { MsalProvider } from "@azure/msal-react";
import { msalApp } from "./constants/auth.constant";

initializeIcons();

export default function App() {
    const queryClient = new QueryClient();

    return (
        <MsalProvider instance={msalApp}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route path='' element={<Navigate to='/gpt' />} />
                            <Route path='/gpt' element={<GptChat />} />
                            <Route path='/policy' element={<PolicyGptChat />} />
                            <Route path='*' element={<Navigate to='/gpt' />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
        </MsalProvider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
