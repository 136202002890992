import { LOGIN_SCOPES, msalApp } from "../constants/auth.constant";
import {InteractionRequiredAuthError , BrowserAuthError} from "@azure/msal-browser";

const useAuthService = () => {

    const getUserAccessToken = async () => {
        if (!msalApp) return null;

        const activeAccount = msalApp.getActiveAccount();
        const accounts = msalApp.getAllAccounts();

        if (!activeAccount && accounts.length === 0) return null;

        const request = {
            scopes: LOGIN_SCOPES,
            account: activeAccount || accounts[0]
        };

        try {
            const authResult = await msalApp.acquireTokenSilent(request);
            return authResult.accessToken
        }
        catch (error) {
            if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
                await msalApp['browserStorage'].clear()
                const authResult = await msalApp.acquireTokenSilent(request);
                return authResult.accessToken
            } else {
                console.error(error);
            }

        }

    }

    const getUserIDToken = () => {
        if (!msalApp) return null;

        const activeAccount = msalApp.getActiveAccount();
        const accounts = msalApp.getAllAccounts();

        if (!activeAccount && accounts.length === 0) return null;

        const currentAccount = activeAccount || accounts[0];

        return currentAccount.idToken
    }

    const getActiveUserDetails = () => {
        if (!msalApp) return null;

        const activeAccount = msalApp.getActiveAccount();
        const accounts = msalApp.getAllAccounts();

        if (!activeAccount && accounts.length === 0) return null;

        const currentAccount = activeAccount || accounts[0];

        return currentAccount
    }

    const logout = () => {
        return new Promise((resolve, reject) => {
            if (!msalApp) reject('Invalid msal client');

            msalApp.logoutRedirect({
                postLogoutRedirectUri: '/'
            })
            .then(() => resolve('User logged out successfully'))
            .catch(reject);
        });
    }

    return {
        getUserAccessToken,
        getUserIDToken,
        getActiveUserDetails,
        logout
    }
}

export default useAuthService;