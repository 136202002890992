import { ConversationType } from "../../constants/chat.constant";
import { ChatFolderDetail } from "../../interfaces/chat.interface";
import { ConversationComponent } from "./conversation";


interface Props {
  conversationType: ConversationType;
  conversations: Array<ChatFolderDetail>;
}

export const Conversations = ({ conversations, conversationType }: Props) => {
  return (
    <div className="flex w-full flex-col gap-1">
      {conversations
        .slice()
        .reverse()
        .map((conversation, index) => (
          <ConversationComponent key={index} conversation={conversation} conversationType={conversationType} />
        ))}
    </div>
  );
};
