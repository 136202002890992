import { useContext, useState } from "react";
import { ChatContext } from "../../store/chat.store";
import ChatHistory from "./chat-history";
import { IconCheck, IconTrash, IconX } from "@tabler/icons-react";
import { v4 as uuidv4 } from 'uuid';
import useChatLocalService from "../../services/chat-local.service";
import { ConversationType } from "../../constants/chat.constant";
import useChatApiService from "../../services/chat.service";
import toast from "react-hot-toast";

interface Props {
    conversationType: ConversationType;
    onNewChat: () => void;
}

export default function ChatSideBar({
    conversationType,
    onNewChat
}: Props) {
    const { state, dispatch } = useContext(ChatContext);
    const { clearAll } = useChatLocalService();
    const [clearConversationConfimation, setClearConversationConfimation] = useState<boolean>(false);
    const { saveSelectedConversation } = useChatLocalService();
    const { deleteAllChatConversation } = useChatApiService();

    // Event listeners

    const handleClearConversations = () => {
        dispatch({ field: 'isLoading', value: true });

        deleteAllChatConversation(conversationType).then((response) => {
            // Clear all the conversations
            clearAll();

            // Create a new empty conversation
            const newConversation = {
                conversation_id: uuidv4(),
                title: 'New Conversation',
                conversation_type: conversationType
            };
            dispatch({ field: 'conversations', value: [newConversation] });
            
            dispatch({ field: 'conversationStatusMap', value: state.conversationStatusMap.set(newConversation.conversation_id, false) });
            dispatch({ field: 'selectedConversation', value: newConversation });

            saveSelectedConversation(newConversation.conversation_id, newConversation.conversation_type);

            setClearConversationConfimation(false);

            dispatch({ field: 'isLoading', value: false });
            toast.success(response.message);
        }).catch((err) => {
            console.error('Error occurred while deleting all the user conversations', err);

            dispatch({ field: 'isLoading', value: false });
            toast.error((err && err.error) ? err.error : 'Something went wrong.');
        });
    };

    return (
        <div className="chat-sidebar-wrapper h-full">
            <div className="chat-history">
                <ChatHistory onNewChat={onNewChat} conversationType={conversationType} />
            </div>

            <div className="flex flex-col items-center space-y-1 border-t border-white/20 pt-1 text-sm">
                {state.conversations.length > 0 && (
                    clearConversationConfimation ? (
                        <div className="flex w-full cursor-pointer items-center py-5 px-5 hover:bg-gray-500/10 text-[#665546] dark:text-white">
                            <IconTrash size={18} />

                            <div className="ml-3 flex-1 text-left text-[12.5px] leading-3">
                                Are you sure?
                            </div>

                            <div className="flex w-[40px]">
                                <IconCheck
                                    className="ml-auto mr-1 min-w-[20px] text-neutral-400 hover:text-neutral-100"
                                    size={18}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleClearConversations();
                                    }}
                                />

                                <IconX
                                    className="ml-auto min-w-[20px] text-neutral-400 hover:text-neutral-100"
                                    size={18}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setClearConversationConfimation(false);
                                    }}
                                />
                            </div>
                        </div>
                    ) : (

                        <button
                            className="flex w-full cursor-pointer select-none items-center gap-3 py-5 px-5 text-[14px] leading-3 text-[#665546] dark:text-white transition-colors duration-200 dark:hover:bg-gray-500/10"
                            onClick={() => setClearConversationConfimation(true)}
                        >
                            <div><IconTrash size={18} /></div>
                            <span>Clear conversations</span>
                        </button>
                    )
                )}
            </div>
        </div>
    )
}