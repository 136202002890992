import { PROGRAMMING_LANG } from '../../constants/code-block.constant';
import { IconCheck, IconClipboard, IconDownload } from '@tabler/icons-react';
import {FC, memo, useContext, useState} from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { v4 as uuidv4 } from 'uuid';
import {ThemeContext} from "../../store/theme.store";

interface Props {
  language: string;
  value: string;
}

export const CodeBlock: FC<Props> = memo(({ language, value }) => {
  const [isCopied, setIsCopied] = useState<Boolean>(false);
  const { state, dispatch } = useContext(ThemeContext);

  const copyToClipboard = () => {
    if (!navigator.clipboard || !navigator.clipboard.writeText) {
      return;
    }

    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };
  const downloadAsFile = () => {
    const fileExtension = PROGRAMMING_LANG[language] || '.file';
    const suggestedFileName = `file-${uuidv4().substring(0, 3)}${fileExtension}`;
    const fileName = window.prompt(
      'Enter file name',
      suggestedFileName,
    );

    if (!fileName) {
      // user pressed cancel on prompt
      return;
    }

    const blob = new Blob([value], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  return (
    <div className="codeblock relative font-sans text-[16px] mb-3">
      <div className="flex items-center justify-between py-1.5 px-4">
        <span className={`text-xs lowercase  ${state.isDarkTheme ? "text-white":"text-black"}`} >{language}</span>

        <div className={`flex items-center`}>
          <button
            className={`flex gap-1.5 items-center bg-none p-1 text-xs   ${state.isDarkTheme ? "text-white":"text-black"}`}
            onClick={copyToClipboard}
          >
            {isCopied ? <IconCheck size={18} /> : <IconClipboard size={18} color={(state.isDarkTheme ? 'white' : 'black')} />}
            {isCopied ? 'Copied!' : 'Copy code'}
          </button>
          <button
            className="flex items-center bg-none p-1 text-xs text-white"
            onClick={downloadAsFile}
          >
            <IconDownload size={18} color={(state.isDarkTheme ? 'white' : 'black')} />
          </button>
        </div>
      </div>

      <SyntaxHighlighter
        language={language}
        style={oneDark}
        customStyle={{ margin: 0 }}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  );
});
CodeBlock.displayName = 'CodeBlock';
