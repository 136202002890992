import {
  IconRobot,
  IconUser,
} from '@tabler/icons-react';
import { FC, memo, useEffect, useState } from 'react';
import { CodeBlock } from '../markdown/code-block';
import { MemoizedReactMarkdown } from '../markdown/memoized-react-markdown';
import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { Message } from '../../interfaces/chat.interface';
import useChatApiService from "../../services/chat.service";
import {Popup} from "../popup/Popup";
import citation from '../../assets/citation.svg'
import FullScreenLoader from "../loader/fullscreen-loader";

interface Props {
  message: Message;
  messageIndex: number;
}

export const ChatMessage: FC<Props> = memo(({ message, messageIndex }) => {
  const [citationMessage, setCitationMessage] = useState<string>()
  const [citationSource, setCitationSource] = useState<[any]>()
  const [openModal,setOpenModal] = useState<boolean>(false)
  const[reference,setReference] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {getPresignedUrl}  = useChatApiService()


  useEffect(() => {

    if (message.sources && message.role === 'assistant') {
      const parsedJsonSource = JSON.parse(message.sources)
      setCitationSource(parsedJsonSource)
    }

    const modified_content = message.content.replace(/\[([0-9]+)\]/g, (match, number) => {
      return `[${match}](${number})`;
    })
    setCitationMessage(modified_content)
  }, [message]);


  const closeModal = () => {
    setOpenModal(false)
  }

  // @ts-ignore
  return (
    <div
      className={`group px-4 ${message.role === 'assistant'
        ? 'text-gray-800 dark:text-gray-100'
        : 'text-gray-800 dark:text-gray-100'
        }`}
      style={{ overflowWrap: 'anywhere' }}
    >
      <div className={`relative m-auto flex items-center gap-4 p-4 text-base md:max-w-2xl md:gap-6 md:py-6 lg:max-w-2xl lg:px-0 xl:max-w-3xl ${message.role === 'assistant' ? 'flex-row' : 'flex-row-reverse'}`}>
        <div className="min-w-[40px] text-right font-bold self-start">
          {message.role === 'assistant' ? (
            <IconRobot size={30} color={'#D01C65'} />
          ) : (
            <IconUser size={30} className='mt-[8px]' />
          )}
        </div>

        <div className="prose mt-[-2px] w-full dark:prose-invert">
          {message.role === 'user' ? (
            <div className="flex w-full flex-row-reverse">
              <div className="prose bg-[#fbfbfb] dark:bg-[#181818] py-3 px-4 whitespace-pre-wrap dark:prose-invert">
                {message.content}
              </div>
            </div>
          ) : (
            <>
              <MemoizedReactMarkdown
                className="prose dark:prose-invert pr-6"
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeMathjax]}
                components={{
                  code({ node, inline, className, children, ...props }: any) {
                    const match = /language-(\w+)/.exec(className || '');

                    return !inline ? (
                      <CodeBlock
                        key={Math.random()}
                        language={(match && match[1]) || ''}
                        value={String(children).replace(/\n$/, '')}
                        {...props}
                      />
                    ) : (
                      <>
                        <pre className="bg-gray-100 p-4 rounded overflow-auto">
                          <code className={className} {...props}>
                            {children}
                          </code>
                        </pre>
                      </>

                    );
                  },
                  table({ children }) {
                    return (
                      <table className="border-collapse border border-black px-3 py-1 dark:border-white">
                        {children}
                      </table>
                    );
                  },
                  th({ children }) {
                    return (
                      <th className="break-words border border-black bg-gray-500 px-3 py-1 text-white dark:border-white">
                        {children}
                      </th>
                    );
                  },
                  td({ children }) {
                    return (
                      <td className="break-words border border-black px-3 py-1 dark:border-white">
                        {children}
                      </td>
                    );
                  },
                  a({ children, href }) {
                    const regex = /\[([0-9]+)\]/g;
                    let isCitation;
                    if (children) {
                      isCitation = regex.test(children as string)
                    }

                    return (
                      <>
                        {isCitation ? (
                           <img src={citation} className="inline-block hover:cursor-pointer" alt="citation Logo"
                                width={20} height={20} data-tooltip-target="tooltip-default"
                                onClick={() => {
                                  setIsLoading(true)
                                     if (href && citationSource != null) {
                                       const currentCitation = citationSource[(+href) - 1]
                                       getPresignedUrl({url:citationSource[(+href) - 1].url},false)
                                           .then((response) => {

                                             if (response) {
                                               currentCitation.url = response.url
                                               setReference(currentCitation)
                                               setIsLoading(false)
                                               setOpenModal(true)
                                             }else{
                                               setReference(citationSource[(+href) - 1])
                                               setIsLoading(false)
                                             }
                                           })
                                           .catch((err) => {
                                             console.error('ERROR: Error occurred while getting presigned url response', err);
                                             setIsLoading(false)
                                           })

                             }
                           }}/>
                          ) : (<a href={href} className="text-[#33a0bb] font-medium">
                      {children}

                      </a>)
                  }
                  </>


                  )
                  },
                  h1({children }) {
                    return (
                      <h1>{children}</h1>
                    )
                  },
                  h2({ children }) {
                    return (
                      <h2>{children}</h2>
                    )
                  },
                  h3({ children }) {
                    return (
                      <h3>{children}</h3>
                    )
                  },
                  h4({ children }) {
                    return (
                      <h4>{children}</h4>
                    )
                  },
                  h5({ children }) {
                    return (
                      <h5>{children}</h5>
                    )
                  },
                  h6({ children }) {
                    return (
                      <h6>{children}</h6>
                    )
                  },

                  ol({ children }) {
                    return (
                      <ol className="list-decimal my-4">{children}</ol>
                    )
                  },
                  ul({ children }) {
                    return (
                      <ul className="list-disc my-4">{children}</ul>
                    )
                  },
                  li({ children }) {
                    return (
                      <li className="ml-6 ">{children}</li>
                    )
                  }

                }}
              >
                {citationMessage ? citationMessage : message.content}
              </MemoizedReactMarkdown>


            </>
          )}
        </div>
      </div>
        {openModal && <Popup reference={reference} closeModal={closeModal}/>}
        {isLoading && <FullScreenLoader />}


    </div>
  );
});
ChatMessage.displayName = 'ChatMessage';
