import { useEffect, useState } from "react";
import useThemeLocalService from "../services/theme-local.service";
import { THEME } from "../constants/app.constant";

export const useThemeDetector = () => {
    const { getTheme, setTheme } = useThemeLocalService();
    const localTheme = getTheme();

    const getCurrentTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches;
    const [isDarkTheme, setIsDarkTheme] = useState(localTheme ? (localTheme === THEME.DARK) : getCurrentTheme());

    const mqListener = (e: any) => {
        setTheme(e.matches ? THEME.DARK : THEME.LIGHT);
        setIsDarkTheme(e.matches);
    };
    
    useEffect(() => {
      const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      darkThemeMq.addEventListener('change', mqListener);
      
      return () => darkThemeMq.removeEventListener('change', mqListener);
    }, []);
    
    return isDarkTheme;
}