import {useEffect} from "react";

interface Props {
    reference:any;
    closeModal:() => void
}


export const Popup = ({reference , closeModal}:Props) => {
    return (
        <div id="default-modal" tabIndex={-1} aria-hidden="true"
             className="overflow-y-auto overflow-x-hidden fixed top-0 left-20 right-0  z-50 justify-center items-center w-full  h-[calc(100%-1rem)] max-h-full
             flex backdrop-blur-md
             ">
            <div className="relative p-4 w-full max-w-2xl max-h-full">

                <div className="relative bg-white rounded-lg shadow dark:bg-[#000]">

                    <div
                        className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <div className="flex justify-between">
                            <p className="text-xl font-semibold text-gray-900 dark:text-white">
                                Reference
                            </p>
                        </div>

                        <button type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-hide="default-modal"
                                onClick={closeModal}
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        <div className="flex justify-between">
                            <p className="text-xl font-semibold text-gray-900 dark:text-white">
                                Content
                            </p>
                            <a href={reference.url}
                               className="text-[#33a0bb]  font-medium rounded-lg text-sm">
                                Download Reference
                            </a>
                        </div>


                        <p className="text-base leading-relaxed text-gray-500 dark:text-white mb-4">
                            {reference.content}
                        </p>
                        {/*<p className="text-xl font-semibold text-gray-900 dark:text-white">*/}
                        {/*    Document Url*/}
                        {/*</p>*/}

                    </div>

                </div>
            </div>
        </div>
    )
}